import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

let alreadyLoaded = false;

const Workflow = () => {
  const [loaded, setLoaded] = useState(alreadyLoaded);
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('workflow-bundle');

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.WORKFLOW_UI) {
      window.WORKFLOW_UI.render(document.getElementById('workflow'));
    }
    return () => {
      window.WORKFLOW_UI?.root?.unmount();
    };
  }, [loaded]);

  const handleScriptInjectCB = useCallback((_newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.WORKFLOW), () => {
      setLoaded(true);

      logHandleTime();
      alreadyLoaded = true;
      if (window.WORKFLOW_UI) {
        window.WORKFLOW_UI.render(document.getElementById('workflow'));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.WorkflowUI);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  src: `${baseUrl}${releasePostfix}/wfe/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/wfe/static/css/main.css`}
        />
      </Helmet>
      <div id="workflow" className="Workflow" />
    </>
  );
};

export default Workflow;
