import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

let loaded = false;

const CprmReact = () => {
  const { getApiUrl } = useApi();
  const { handleScriptInject } = useScriptInject();
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime(`cprm-ui-bundle`);

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.CPRM_UI_REACT) {
      window.CPRM_UI_REACT.render(document.getElementById('cprm-ui'));
    }
    return () => {
      window.CPRM_UI_REACT?.root?.unmount();
    };
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.CPRM_UI_REACT), () => {
      logHandleTime();
      loaded = true;
      if (window.CPRM_UI_REACT) {
        window.CPRM_UI_REACT.render(document.getElementById('cprm-ui'));
      }
    });
  }, []);

  const baseUrl = useMemo(() => getApiUrl(Service.CprmUIReact), []);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  type: 'module',
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id="cprm-ui" className="CPRM-UI" />
    </>
  );
};

export default CprmReact;
